/* source-serif-pro-400normal - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Source Serif Pro Regular '),
    local('Source Serif Pro-Regular'),
    url('./files/source-serif-pro-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/source-serif-pro-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* source-serif-pro-600normal - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Source Serif Pro SemiBold '),
    local('Source Serif Pro-SemiBold'),
    url('./files/source-serif-pro-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/source-serif-pro-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* source-serif-pro-700normal - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Source Serif Pro Bold '),
    local('Source Serif Pro-Bold'),
    url('./files/source-serif-pro-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/source-serif-pro-latin-700.woff') format('woff'); /* Modern Browsers */
}

